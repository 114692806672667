import * as React from 'react';
import Layout from '../../components/Layout';

const Overview = props=>{
    return (
        <Layout>
            {/* <div className="blog-content" style={{background:'#fff'}} data-compid="section-0-0">
                <div className="container twocolumn" id="">
                    <div className="copy">
                        <p>We're moving forward to create a greater New Zealand for everyone.</p>
                        <p>We're doing this as an organisation through our three main brand pillars: Sustainability, Mobility and Society.</p>
                    </div>
                </div>
            </div> */}
        </Layout>
    );
}

export default Overview;
